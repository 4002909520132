<template>
  <a href="mailto:sale@term-spb.ru">sale@term-spb.ru</a>
</template>

<script>
export default {
  name: "MailPrimary",
}
</script>

<style scoped>

</style>