<template>
  <div class="order-create__success">
    <div class="order-create__success__title">Заявка успешно отправлена</div>
    <div class="order-create__success__description">В ближайшее время с вами свяжуться наши менеджеры</div>
    <button-text-primary class="order-create__success__button" @click="onClickSuccessButton">Создать новую заявку</button-text-primary>
  </div>
</template>

<script>
import ButtonTextPrimary from "@/shared/ui/ButtonTextPrimary";
export default {
  name: "OrderCreateSuccess",
  components: {ButtonTextPrimary},
  emits:['isSuccess'],
  // mounted() {
  //   setTimeout(() =>{
  //     this.$refs.modalSuccess.toShow()
  //   }, 1)
  // },
  methods:{
    onClickSuccessButton: function (){
      // this.$refs.modalSuccess.toHide()
      this.$emit('isSuccess', false)
    }
  }
}
</script>

<style lang="stylus">
@import "@/shared/styl/variables.styl"
.order-create__success
  text-align center
  &__title
    font-weight 600
    font-size 20px
    margin-bottom 10px
    color $white
    @media screen and (min-width 768px)
      font-size 24px
  &__description
    color $white
  &__button
    margin-top 30px
</style>