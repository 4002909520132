export const hasEmptyRequireOption = (array) => {
    const arrayRequire = array.filter(item => item.isRequire) // ищет только те, которые обязательны
    let hasEmptyOption = false
    for(let i = 0; i < arrayRequire.length; i++){
        // перебираем объекты и ищем пустые
        switch (!!arrayRequire[i].value){
            case false:
                arrayRequire[i].note = arrayRequire[i].noteEmpty() // активируем сообщение о пустом поле
                hasEmptyOption = true // возвращаем найдено и выходим из функции
                break
        }
    }
    return hasEmptyOption
}

export const hasEmptyOption = (array) => {
    const arrayRequire = array.filter(item => item.isRequire) // ищет только те, которые обязательны
    let hasEmptyOption = false
    for(let i = 0; i < arrayRequire.length; i++){
        // перебираем объекты и ищем пустые
        switch (!!arrayRequire[i].value){
            case false:
                hasEmptyOption = true // возвращаем найдено и выходим из функции
                break
        }
    }
    return hasEmptyOption
}

export const hasInvalidOption = (array) => {
    let hasInvalidOption = false
    const arrayValidation = array.filter(item => item.hasValidation) // ищет только те, где нужна валидация
    for(let i = 0; i < arrayValidation.length; i++){
        // перебираем объекты и ищем не валид
        switch (arrayValidation[i].isValid()){
            case false:
                arrayValidation[i].note = arrayValidation[i].noteInvalid() // активируем сообщение о невалидном поле
                hasInvalidOption = true // возвращаем найдено и выходим из функции
                break
        }
    }
    return hasInvalidOption // если найдено не было возвращаем презаданный false
}
