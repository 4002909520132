export default {
    mutations: {

    },
    state: {
        policy: {
            date: '',
            // content: `<p>Политика</p>`
            content: `
<h1>Политика конфиденциальности интернет-сайта</h1>
<p>г. Санкт Петербург</p>
<p>"07" ноября 2023 г.</p>
<p>Настоящая   Политика   конфиденциальности   персональных   данных   (далее   -   Политика
конфиденциальности)   является  неотъемлемой  частью Публичной  оферты,  размещенной  на   сайте  в   сети
Интернет по адресу: <a href="https://term-spb.ru/">https://term-spb.ru/</a> (далее - Сайт).</p>
<p>Использование   сервисов   Сайта   означает   безоговорочное   согласие   Пользователя   с   настоящей
Политикой и указанными в ней условиями обработки его персональной информации; в случае несогласия с
этими условиями Пользователь должен воздержаться от использования сервисов.</p>
<h2>Общие положения</h2>
<ol>
    <li>1.1. В рамках настоящей Политики под персональной информацией Пользователя понимаются:</li>
    <li>1.1.1.  Персональная информация,  которую Пользователь предоставляет о себе самостоятельно при
регистрации (создании учетной записи) или в процессе использования Сервисов,  включая персональные
данные  Пользователя.  Обязательная   для   предоставления  Сервисов   информация   помечена   специальным
образом.</li>
    <li>1.1.2.  Данные, которые автоматически передаются сервисам Сайта в процессе их использования с
помощью установленного на устройстве Пользователя программного обеспечения,  в  том числе IP-адрес,
данные файлов cookie,  информация о браузере Пользователя (или иной программе, с  помощью которой
осуществляется   доступ   к   сервисам),   технические   характеристики   оборудования   и   программного
обеспечения,   используемых   Пользователем,   дата   и   время   доступа   к   сервисам,   адреса   запрашиваемых
страниц и иная подобная информация.</li>
    <li>1.1.3.  Настоящая  Политика   конфиденциальности  применяется   только   к  Сайту  <a href="https://term-spb.ru/">https://term-spb.ru/</a>  .
Сайт  <a href="https://term-spb.ru/">https://term-spb.ru/</a>  не  контролирует  и  не  несет  ответственности  за  сайты третьих  лиц,  на  которые
Пользователь может перейти по ссылкам, доступным на Сайте <a href="https://term-spb.ru/">https://term-spb.ru/</a> </li>
</ol>
<h2>2. ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЕЙ</h2>
<ol>
    <li>2.1.   Сайт   собирает   и   хранит   только   ту   персональную   информацию,   которая   необходима   для
предоставления   сервисов   или   исполнения   соглашений   и   договоров   с   Пользователем,   за   исключением
случаев,   когда   законодательством   предусмотрено   обязательное   хранение   персональной   информации   в
течение определенного законом срока.</li>
<li>В случае получения уведомления от Пользователя об отзыве согласия на обработку персональных
данных  Сайт   прекращает   обработку   персональных   данных  Пользователя   в   срок,   не   превышающий   10
рабочих дней с момента получения.</li>
<li>Уведомление   об   отзыве   согласия   на   обработку   персональных   данных   направляется   на   адрес
электронной почты:  <a href="mailto:sale@term-spb.ru">sale@term-spb.ru</a>, а также путем письменного обращения по юридическому адресу: г.
Санкт-Петербург, вн.тер. Муниципальный округ Полюстрово, пр-кт Пискарёвский, д. 63, лит. А, офис 148,
пом.3-Н, 195273.</li>
    <li>2.2. Персональную информацию Пользователя Сайт обрабатывает в следующих целях:</li>
    <li>2.2.1. Идентификации Пользователя, зарегистрированного на Сайте, для <a href="mailto:sale@term-spb.ru">sale@term-spb.ru</a>.</li>
    <li>2.2.2. Предоставления Пользователю доступа к персонализированным ресурсам Сайта.</li>
    <li>2.2.3. Установления с Пользователем обратной связи, включая направление уведомлений, запросов,
касающихся использования Сайта, оказания услуг, обработку запросов и заявок от Пользователя.</li>
    <li>2.2.4. Определения места нахождения Пользователя для обеспечения безопасности, предотвращения
мошенничества.</li>
    <li>2.2.5.   Подтверждения   достоверности   и   полноты   персональных   данных,   предоставленных
Пользователем.</li>
    <li>2.2.6. Уведомления Пользователя Сайта о деятельности  ООО "ТЕРМИНАЛ СПБ" .</li>
    <li>2.2.7.   Предоставления   Пользователю   эффективной   клиентской   и   технической   поддержки   при
возникновении проблем, связанных с использованием Сайта.</li>
    <li>2.2.8. Осуществления рекламной деятельности с согласия Пользователя.</li>
</ol>

<h2>3. УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЕЙ
И ЕЕ ПЕРЕДАЧИ ТРЕТЬИМ ЛИЦАМ</h2>
<ol>
    <li>3.1.   Сайт   хранит   персональную   информацию   Пользователей   в   соответствии   с   внутренними
регламентами конкретных сервисов.</li>
    <li>3.2.   В   отношении   персональной   информации   Пользователя   сохраняется   ее   конфиденциальность,
кроме   случаев   добровольного   предоставления  Пользователем   информации   о   себе   для   общего   доступа
неограниченному кругу лиц. При использовании отдельных сервисов Пользователь соглашается с тем, что
определенная часть его персональной информации становится общедоступной.</li>
    <li>3.3.  Сайт  вправе  передать  персональную информацию Пользователя  третьим лицам в   следующих
случаях:</li>
    <li>3.3.1. Пользователь выразил согласие на такие действия.</li>
    <li>3.3.2.   Передача   необходима   для   использования   Пользователем   определенного   сервиса   либо   для
исполнения определенного соглашения или договора с Пользователем.</li>
    <li>3.3.4.   Передача   предусмотрена   российским   или   иным   применимым   законодательством   в   рамках
установленной законодательством процедуры.</li>
    <li>3.3.5. В случае продажи Сайта к приобретателю переходят все обязательства по соблюдению условий
настоящей Политики применительно к полученной им персональной информации.</li>
    <li>3.4.   Обработка   персональных   данных   Пользователя   осуществляется   без   ограничения   срока
следующими   способами:   сбор,   запись,   систематизация,   накопление,   хранение,   уточнение   (обновление,
изменение),   извлечение,   использование,   передача   (распространение,   предоставление,   доступ),
обезличивание,   блокирование,   удаление,   уничтожение   персональных   данных,   в   том   числе   в
информационных   системах   персональных   данных   с   использованием   средств   автоматизации   или   без
использования   таких   средств.   Обработка   персональных   данных   Пользователей   осуществляется   в
соответствии с Федеральным законом от 27.07.2006 N 152-ФЗ "О персональных данных".</li>
    <li>3.5.   При   утрате   или   разглашении   персональных   данных   Администрация   Сайта   информирует
Пользователя об утрате или разглашении персональных данных.</li>
    <li>3.6. Администрация Сайта принимает необходимые организационные и технические меры для защиты
персональной   информации   Пользователя   от   неправомерного   или   случайного   доступа,   уничтожения,
изменения,   блокирования,   копирования,   распространения,   а   также   от   иных   неправомерных   действий
третьих лиц.</li>
    <li>3.7.   Администрация   Сайта   совместно   с   Пользователем   принимает   все   необходимые   меры   по
предотвращению убытков  или  иных отрицательных последствий,  вызванных утратой  или разглашением
персональных данных Пользователя.</li>
</ol>
<h2>4. ОБЯЗАТЕЛЬСТВА СТОРОН</h2>
<ol>
    <li>4.1. Пользователь обязан:</li>
    <li>4.1.1. Предоставить информацию о персональных данных, необходимую для пользования Сайтом.</li>
    <li>4.1.2.   Обновлять,   дополнять   предоставленную   информацию   о   персональных   данных   в   случае
изменения данной информации.</li>
    <li>4.2. Администрация Сайта обязана:</li>
    <li>4.2.1.  Использовать   полученную  информацию  исключительно   для   целей,   указанных   в   настоящей
Политике конфиденциальности.</li>
    <li>4.2.2.   Обеспечить   хранение   конфиденциальной   информации   в   тайне,   не   разглашать   без
предварительного   письменного   разрешения   Пользователя,   а   также   не   осуществлять   продажу,   обмен,
опубликование   либо   разглашение   иными   возможными   способами   переданных   персональных   данных
Пользователя, за исключением предусмотренных настоящей Политикой конфиденциальности.</li>
    <li>4.2.3.   Для   обеспечения   защиты   персональных   данных   Пользователя   при   их   обработке   приняты
соответствующие   правовые,   организационные   и   технические   меры   от   несанкционированного,
неправомерного или случайного доступа к персональным данным, уничтожения, изменения, блокирования,
копирования,  предоставления,  распространения  персональных  данных,   а   также  от  иных  неправомерных
действий в отношении персональных данных. </li>
    <li>4.2.4.   Осуществить   блокирование   персональных   данных,   относящихся   к   соответствующему
Пользователю,   с  момента   обращения  или   запроса  Пользователя  или   его   законного  представителя   либо
уполномоченного органа по защите прав субъектов персональных данных на период проверки в случае
выявления недостоверных персональных данных или неправомерных действий.</li>
</ol>
<h2>5. ОТВЕТСТВЕННОСТЬ СТОРОН</h2>
<ol>
    <li>5.1.  Администрация  Сайта,  не  исполнившая свои обязательства,  несет  ответственность  за  убытки,
понесенные Пользователем в связи с неправомерным использованием персональных данных, в соответствии
с законодательством Российской Федерации.</li>
    <li>5.2. В случае утраты или разглашения конфиденциальной информации Администрация Сайта не несет
ответственности, если данная конфиденциальная информация:</li>
<li>5.2.1. Стала публичным достоянием до ее утраты или разглашения.</li>
<li>5.2.2. Была получена от третьей стороны до момента ее получения Администрацией Сайта.</li>
<li>5.2.3. Была разглашена с согласия Пользователя.</li>
</ol>

<h2>6. РАЗРЕШЕНИЕ СПОРОВ</h2>
<p>6.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем Сайта
и Администрацией Сайта,  обязательным является  предъявление  претензии  (письменного  предложения  о
добровольном урегулировании спора).</p>
<p>6.2. Получатель претензии в течение 30 календарных дней со дня получения претензии письменно
уведомляет заявителя претензии о результатах рассмотрения претензии.</p>
<p>6.3.  При  недостижении   соглашения  спор  будет  передан  на  рассмотрение  в   суд   в   соответствии   с
действующим законодательством Российской Федерации.</p>
<p>6.4.   К   настоящей   Политике   конфиденциальности   и   отношениям   между   Пользователем   и
Администрацией Сайта применяется действующее законодательство Российской Федерации.</p>

<h2>7. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</h2>
<ol>
    <li>7.1. Администрация Сайта вправе вносить изменения в настоящую Политику конфиденциальности
без согласия Пользователя.</li>
    <li>7.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте, если
иное не предусмотрено новой редакцией Политики конфиденциальности.</li>
    <li>7.3. Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать
путем направления сообщения на электронную почту <a href="mailto:sale@term-spb.ru">sale@term-spb.ru</a></li>
    <li>7.4.  Действующая  Политика   конфиденциальности   размещена   на   странице   по   адресу:  <a href="https://term-spb.ru/">https://term-spb.ru/</a></li>
    <li>7.5.  Настоящая Политика конфиденциальности является неотъемлемой частью Публичной оферты,
размещенной на странице по адресу: <a href="https://term-spb.ru/">https://term-spb.ru/</a>. </li>
</ol>`
        },
        oferta:{
            date: '',
            content: `
<h1>ДОГОВОР ПУБЛИЧНОЙ ОФЕРТЫ
(на организацию перевозок «сборных» грузов)</h1>
<p>Дата публикации<br>07.11.2023 года</p>

<p>Данный документ является официальным предложением
Общества с ограниченной ответственностью
"ТЕРМИНАЛ СПБ" для физических и юридических лиц Российской Федерации заключить договор на оказание
транспортно-экспедиционных услуг на указанных ниже условиях и публикуется на сайте <a href="https://term-spb.ru/">https://term-spb.ru/</a>.</p>
<p>В соответствии с пунктом 2 статьи 437 Гражданского Кодекса Российской Федерации данный документ является
публичной офертой.</p>
<p>Настоящая публичная оферта на оказание услуг (далее Договор) заключается в особом порядке: путем акцепта
настоящего Договора, содержащего все существенные условия Договора, без подписания сторонами. Настоящий Договор
имеет юридическую силу в соответствии со ст. 434 Гражданского кодекса Российской Федерации и является равносильным
договору, подписанному сторонами.</p>
<p>Настоящий договор является договором присоединения. Фактом, подтверждающим принятие изложенных ниже
условий, и акцептом настоящей публичной оферты является заказ услуг и/или сдача груза и/или оплата услуг и/или
получение груза (в соответствии с пунктом 3 статьи 438 ГК РФ акцепт оферты равносилен заключению договора на
условиях, изложенных в оферте). Лицо, заказавшее услугу и/или сдавшее груз и/или оплатившее услугу и/или получившее
груз признается Клиентом или представителем Клиента с надлежащими полномочиями.</p>
<p>Настоящий договор считается заключенным с момента его акцептации и действует до исполнения сторонами всех
своих обязательств по настоящему Договору.</p>
<p>ООО "ТЕРМИНАЛ СПБ", именуемое в дальнейшем «Экспедитор», в лице директора Лапикова Егора Васильевича,
действующего на основании Устава, публикует настоящий договор, являющийся публичным договором-офертой в адрес
как физических, так и юридических лиц (в дальнейшем «Клиент») о нижеследующем:</p>

<h2>1. Предмет договора</h2>
<p>Экспедитор обязуется за вознаграждение и за счет Клиента организовать оказание услуг, связанных с перевозкой
грузов Клиента наземным транспортом, согласно поручению Экспедитору. Услуги по Договору оказываются
Экспедитором по технологии «сборного груза», то есть, совместно с грузами иных Клиентов, на следующих
условиях:
<br> - при перевозке груз Клиента размещается в кузове/прицепе транспортного средства совместно с иными грузами
других Клиентов, размещение груза может быть многоярусным;
<br>- в процессе перевозки возможны перегрузки груза, без дополнительного уведомления Клиента. В случае
осуществления услуг по получению и/или доставке груза, при перевозке груза смешанными видами транспорта,
перегрузка производится в обязательном порядке;
<br>- при хранении груза на терминале Экспедитора и при перевозке груза предусмотрен специальный терморежим;
<br>- отправка грузов осуществляется по мере накопления на терминале Экспедитора, сроки доставки грузов
исчисляются после 24:00 часов дня, заявленного в графике отправки как день выхода транспортного средства в
рейс.
</p>

<p>1.2. В связи с особенностями оказания транспортно-экспедиционных услуг Стороны применяют формы экспедиторских
документов, разработанных Экспедитором на основании требований законодательства РФ о транспортно-
экспедиционной деятельности:
<br>- Поручение Экспедитору (далее «Заявка») – подтверждает заказ на транспортно-экспедиционное обслуживание
каждой конкретной партии груза и оформляется путем подачи её Клиентом по форме, размещенной на сайте
Экспедитора <a href="https://term-spb.ru/">https://term-spb.ru/</a>
<br>- Экспедиторская расписка/ транспортная накладная – подтверждает получение Экспедитором груза для
организации перевозки и оформляется за подписью уполномоченных лиц, с оттиском печати сторон. Полномочия
представителя Экспедитора, прибывшего для выполнения Поручения, подтверждаются доверенностью на
представление интересов Экспедитора;
</p>

<p>1.3. По требованию Клиента, либо указанного им грузоотправителя, наряду с Экспедиторской распиской, в качестве
дополнительного экспедиторского документа, может быть использована и накладная грузоотправителя, с указанием
в ней количества принятых грузовых мест. При несовпадении данных по количеству грузовых мест в
Экспедиторской расписке и накладной грузоотправителя Сторонами признаются верными данные, указанные в
Экспедиторской расписке. В случае использования накладной грузоотправителя в качестве дополнительного
экспедиторского документа, в том числе, путем внесения информации о его номере, дате в экспедиторскую
расписку, груз считается принятым Экспедитором с объявленной ценностью.</p>

<h2>2. Права и обязанности сторон</h2>
<p>2.1. Экспедитор обязан:</p>
<p>2.1.1. Оказывать услуги в соответствии с Договором транспортной экспедиции.</p>
<p>2.1.2. Принимать у Клиента либо лица, указанного Клиентом в Заявке в качестве отправителя, груз по весу, объему и
количеству мест, без проверки содержимого упаковки на предмет: ассортимента, работоспособности, внутренней
комплектации, наличия явных и скрытых дефектов, количества, качества, чувствительности к температурному
воздействию и иных данных. Если приемка груза происходит в пункте, указанном Клиентом в качестве пункта
местонахождения груза, то груз принимается по количеству мест. По соглашению сторон, Экспедитор и Клиент
могут предусмотреть иной порядок приема груза, в том числе, с досмотром внутреннего содержимого,
принимаемого к перевозке груза. В этом случае стороны заключают дополнительное соглашение, в котором отражают обязательства сторон при приеме груза с досмотром внутреннего содержимого и порядок оплаты услуг
Экспедитора.</p>
<p>2.1.3. Обеспечить, в случае подачи Клиентом письменной Заявки, доставку груза, полученного от Клиента или его
поставщиков до склада Экспедитора в пункте отправления, самостоятельно определить вес, объем и по результатам
этих действий составить Накладные без участия Клиента и его поставщиков на основании действующих тарифов
Экспедитора. При измерении объема партии груза учитывать общую высоту, ширину и длину пространства,
занимаемого составленными рядом всех мест партии груза. Определять объем груза с учетом: фактического объема
груза, пустот между отдельными грузовыми местами (не заполняемый объем), состояния упаковки и характера
груза (возможность обеспечивать многоярусную загрузку). Выдать Клиенту документы, подтверждающие прием
груза для организации перевозки, в соответствии с п.п.1.2. 1.3. настоящего договора.</p>
<p>2.1.4. Организовать перевозку груза со склада в пункте отправления до склада в пункте назначения или организовать
доставку по адресу, указанному в Накладной, и выдать груз получателю, указанному в Накладной либо
уполномоченному им лицу по количеству принятых грузовых мест, после полной оплаты услуг Экспедитора.
Моментом окончания доставки груза для жилых зданий/помещений и/или офисов является доставка до подъезда.</p>
<p>2.1.5. Осуществить выдачу груза получателю либо уполномоченному им лицу при предъявлении документа,
удостоверяющего личность, надлежащим образом оформленной доверенности, содержащей подпись и печать, а при
необходимости иных документов, подтверждающих полномочия Грузополучателей. Экспедитор также вправе
запросить копии документов, удостоверяющих личность доверенного лица.</p>
<p>2.1.6. По поручению и за счет Клиента организовать «возврат сопроводительных документов». Услуга по возврату
Клиенту подписанных получателем документов оказывается Экспедитором только при организации перевозок
грузов автомобильным транспортом и оплачивается Клиентом дополнительно по тарифам Экспедитора. Клиент
обязан получить либо обеспечить получение возвратных документов в срок не позднее 5 (пяти) рабочих дней с
момента направления уведомления о возврате документов. Уведомление направляется в порядке, определенном п.
2.1.8. Договора. В случае необходимости передачи Грузополучателю оригинальных документов на груз, без заказа
данной услуги, Клиент самостоятельно, до передачи груза Экспедитору, вкладывает эти документы в груз либо
отправляет их по отдельной Накладной, а заверенные копии документов представляет Экспедитору для
организации перевозки.</p>
<p>2.1.7. По запросу Клиента предоставить информацию о статусе и местонахождении груза. В качестве способов
уведомления, Экспедитор вправе использовать сообщения по электронной почте, по телефонной/факсимильной
связи, смс-сообщения по предоставленным Клиентом контактам.</p>
<p>2.1.8. Предоставить Клиенту информацию о перечне и стоимости услуг, путем размещения тарифов на сайте Экспедитора
<a href="https://term-spb.ru/">https://term-spb.ru/</a>.</p>
<p>2.2. Экспедитор вправе:</p>
<p>2.2.1. Привлекать для исполнения своих обязанностей третьих лиц. Возложение исполнения обязательства на третье лицо
не освобождает Экспедитора от ответственности перед Клиентом за исполнение настоящего Договора, если иное
не предусмотрено действующим законодательством.</p>
<p>2.2.2. Самостоятельно выбирать или изменять вид транспорта, маршрут перевозки груза, последовательность перевозки
груза различными видами транспорта исходя из интересов Клиента.</p>
<p>2.2.3. По поручению и за счет Клиента осуществить дополнительную упаковку груза, без вскрытия упаковки Клиента.
При этом на сайте Экспедитора <a href="https://term-spb.ru/">https://term-spb.ru/</a> размещен перечень грузов, требующих по своей природе особых
условий перевозки. В случае сдачи Клиентом груза, указанного в перечне, Экспедитор вправе:</p>
<p>- дополнительно упаковать груз своими силами за счет Клиента. Подписанием настоящего договора, Клиент
выражает согласие с дополнительной жесткой упаковкой груза, независимо от факта заказа услуги в Заявке и
обязуется оплатить услугу по дополнительной упаковке груза по тарифам, размещенным на сайте Экспедитора.
Данные правила применяются также в случае, когда грузы были сданы Клиентом под неправильным
наименованием;</p>
<p>- организовать перевозку без дополнительной жесткой упаковки, при наличии письменного требования/отказа от
дополнительной жесткой упаковки со стороны Грузоотправителя/Клиента. В указанном случае Клиент принимает
на себя все риски повреждения груза вследствие отсутствия надлежащей упаковки;</p>
<p>- отказаться от приемки такого груза.</p>
<p>Дополнительная упаковка груза не освобождает Клиента от обязательств по обеспечению надлежащей
внутренней/внутритарной упаковки груза. Жесткая упаковка изготавливается с целью защиты груза при
организации перевозки и может быть по ее завершению разобрана Экспедитором до момента выдачи груза
Клиенту.</p>
<p>2.2.4. Удерживать находящийся в его распоряжение груз до уплаты вознаграждения и возмещения, понесенных им в
интересах Клиента расходов. Удержание возможно не только в отношении груза, услуги, по организации перевозки
которого не оплачены, но и иного груза, находящегося у Экспедитора на момент существования задолженности
Клиента перед Экспедитором. В этом случае Клиент также оплачивает расходы по хранению груза Клиента по
тарифам Экспедитора.</p>
<p>2.2.5. В случае отказа Клиента от уплаты услуг Экспедитора или неоплаты услуг Экспедитора в течение 30 (тридцати)
календарных дней, получить причитающееся вознаграждение за счет реализации груза или части груза Клиента в
порядке, предусмотренном законодательством РФ. Стороны установили внесудебный порядок обращения
взыскания (реализации) на грузы Клиента. При этом условия его реализации (ст.349 и ст.350.1 ГК РФ), в том числе,
способы его продажи, определяются Экспедитором самостоятельно. Экспедитор также вправе утилизировать
данный груз.</p>
<p>2.2.6. По поручению Клиента от имени Экспедитора, за счет и в пользу Клиента застраховать грузы Клиента,
принимаемые Экспедитором для организации перевозки. В случае если груз предъявляется к перевозке с
объявленной ценностью, страхование такого груза является обязательным. Страхование груза Клиента,
принимаемого Экспедитором с объявленной ценностью производится Экспедитором от своего имени и за счет
Клиента. Объявленная ценность груза не может превышать его реальную (документально подтвержденную)
стоимость. Клиент возмещает расходы Экспедитора по организации страхования груза согласно выставленному
счету. Отказ от услуги страхования после сдачи груза не принимается Экспедитором.</p>
<p>2.2.7. Считать груз не предъявленным для организации перевозки Клиентом в случаях: предъявления груза для
перевозки, в пункте, указанном Клиентом в качестве пункта местонахождения груза с опозданием, предъявления
для перевозки груза, направляемого в иной пункт назначения, чем установлено Заявкой, предъявление для
перевозки груза, не предусмотренного Заявкой (в том числе и в случаях превышения заявленных параметров
груза), несоответствия состояния предъявленного для перевозки груза требованиям, установленным правилами
перевозок грузов.</p>
<p>2.2.8. По письменному запросу Клиента выдать дубликаты ранее предоставлявшихся документов (актов об оказании
услуг, счетов-фактур и других).</p>
<p>2.2.9. Досматривать груз на соответствие заявленных Клиентом сведений. Клиент обеспечивает прибытие
уполномоченного лица для проверки груза в течение 1 (одного) рабочего дня с момента уведомления
Экспедитором.</p>
<p>2.2.10. При выявлении в ходе выполнения поручения Клиента отсутствия полного комплекта сопроводительных
документов на груз, необходимого, в том числе, для его таможенного оформления и транспортировки, а также в
случаях предоставления недостоверной, неполной информации, несоответствия представленных документов грузу,
его весу, количеству мест и стоимости, приостановить выполнение поручения с отнесением платы за простой,
хранение, иных расходов и штрафов за счет Клиента и/или возвратить груз грузоотправителю за счет Клиента.</p>
<p>2.3. Клиент обязан:</p>
<p>2.3.1. До момента заказа услуг, ознакомиться с тарифами Экспедитора, сроками оказания услуг, формами документов,
дополнительными условиями и иной информацией, размещенной на Сайте Экспедитора <a href="https://term-spb.ru/">https://term-spb.ru/</a> на
предмет отслеживания возможных изменений и/или дополнений. Заказ услуг Экспедитора является надлежащим и
достаточным подтверждением того, что Клиент с вышеуказанной информацией ознакомлен в полном объеме.</p>
<p>2.3.2. В случае необходимости доставки груза от адреса Грузоотправителя до склада Экспедитора в месте отправления
груза, либо доставки груза от склада Экспедитора до адреса Грузополучателя по месту получения груза, подать
Заявку на доставку груза от/до адреса Грузоотправителя/Грузополучателя по форме, имеющейся на сайте у
Экспедитора, одним из указанных способов:</p>
<ul>
<li>• посредством заполнения на сайте <a href="https://term-spb.ru/">https://term-spb.ru/</a> предлагаемой Клиенту формы обращения к Экспедитору;</li>
<li>• посредством направления Экспедитору по сети Интернет бланка заявки (поручения Экспедитору), форма
которой размещена для Клиентов на сайте Экспедитора.</li>
</ul>
<p>В случае если Заявка на получение груза подана Клиентом в рабочие дни не позднее 16:00 по местному времени
города исполнения Заявки, то получение груза осуществляется на следующий рабочий день либо во временной
интервал, исчисляемый с даты получения груза в Заявке до ближайшего дня отправки грузов с терминала
Экспедитора в соответствующий город назначения, определяемого графиком отправок грузов, при условии
подтверждения отгрузки грузоотправителем посредством телефонной связи. При заказе услуг Экспедитора по
получению груза Клиент гарантирует, что отправитель Клиента, является надлежащим представителем Клиента.
При изменении условий поручения после сдачи груза Экспедитору, Экспедитор вправе по своему усмотрению
принять их по электронной почте либо факсу, либо затребовать оригинал.</p>
<p>2.3.3.
Своевременно предоставить достоверную информацию о свойствах и характере груза, об условиях его перевозки,
маркировке, весе и другую информацию необходимую Экспедитору для выполнения обязанностей по настоящему
договору, а также необходимые сопроводительные документы на груз. Информация об условиях транспортировки
груза предоставляется Экспедитору в письменной форме. Клиент до сдачи груза к перевозке обязан на каждом
грузовом месте указать пункт назначения груза, наименования грузоотправителя и грузополучателя. В
необходимых случаях каждое место перевозимого груза должно иметь и специальную маркировку.
Ответственность за отсутствие специальной маркировки и его последствия несет Клиент. Экспедитор вправе
самостоятельно промаркировать груз.</p>
<p>2.3.4.
Подготовить груз к перевозке (затарить, упаковать и т.п.), с тем, чтобы обеспечить сохранность груза в пути
следования и невозможность нанесения вреда перевозимым совместно с ним грузам других клиентов, а также
транспортному средству и оборудованию.</p>
<p>2.3.5.
Не сдавать Экспедитору груз, запрещенный к перевозке действующим законодательством РФ, опасный груз, в том
числе легковоспламеняющиеся и взрывоопасные грузы, грузы, содержащие едкие, ядовитые вещества.</p>
<p>2.3.6.
Возместить понесенные Экспедитором расходы по хранению груза, если грузополучатель не востребовал
прибывший груз в срок, предусмотренный тарифами Экспедитора для соответствующего вида перевозки, либо
отказался от его приема. В случае если после сдачи груза, Клиент отказывается от дальнейшего оказания
транспортно-экспедиционных услуг, Клиент оплачивает расходы Экспедитора по вынужденному хранению груза
Клиента, с момента принятия Экспедитором груза, по тарифам Экспедитора. По истечении 30 (тридцати)
календарных дней хранения груза на складе, Экспедитор посредством смс-уведомления на телефонный номер,
содержащийся в экспедиторской расписке и/или посредством электронных средств связи (e-mail-извещение),
запрашивает у Клиента указания относительно дальнейшей судьбы его груза. Смс-уведомление или уведомление по
электронной почте, направленное Экспедитором во всех случаях выполнения условий Договора считается
полученным Клиентом с момента его направления. Письменные указания о дальнейшей судьбе груза Клиент направляет на почтовый ящик <a href="mailto:sale@term-spb.ru">sale@term-spb.ru</a> . Экспедитор выполняет полученные указания при отсутствии
задолженности Клиента и/или Грузоотправителя за оказанные услуги. Если Клиент или Грузополучатель не
предоставит Экспедитору указаний, относительно дальнейшей судьбы груза в течение 4 (четырех) дней после
направления запроса, а также, когда Клиент не предоставил или предоставил неверный номер телефона, адрес
электронной почты, Экспедитор вправе по собственному усмотрению:
<br> - возвратить такой груз Клиенту или Грузоотправителю за счет последнего;
<br> - в порядке, предусмотренном п.2.2.5 настоящего договора реализовать или утилизировать груз.
</p>
<p>2.3.7.
Оплатить простой, холостой пробег транспортного средства и иные непредвиденные и документально
подтвержденные расходы Экспедитора (заезд автотранспорта по нескольким адресам при получении/доставке груза
от/до Клиента и т.д.). Простоем для целей настоящего договора считается нахождение транспортного средства под
погрузкой/выгрузкой свыше норматива, установленного в тарифах Экспедитора. В случае отказа представителя
Клиента/Грузополучателя/Грузоотправителя от подписания соответствующего Акта либо проставления
соответствующих отметок в экспедиторских документах, представитель Экспедитора составляет их в
одностороннем порядке. Документы, составленные в указанном случае в одностороннем порядке, признается
надлежаще оформленными, и лишают Клиента права в дальнейшем заявлять об обратном.</p>
<p>2.3.8.
Своевременно и в полном объеме оплачивать услуги Экспедитора, а также возместить понесенные им расходы в
интересах Клиента. При этом Клиент не вправе производить какие-либо удержания по любым основаниям (в том
числе в порядке погашения претензий к Экспедитору) из сумм, которые Клиент обязан уплатить Экспедитору в
качестве вознаграждения по настоящему договору.</p>
<p>2.3.9.
В случае если страхование груза было организовано Экспедитором, письменно сообщить Экспедитору о
наступлении страхового случая в течение рабочего дня обнаружения порчи/утраты груза.</p>
<p>2.3.10.
В случае изменения Клиентом маршрута следования, вида транспорта, изменения Грузополучателя, либо лица,
осуществляющего оплату услуг Экспедитора, после приема груза Экспедитором, оплатить дополнительные
расходы, а также неустойку, установленную в тарифах Экспедитора (прайс-листах), размещенных последним на
сайте <a href="https://term-spb.ru/">https://term-spb.ru/</a> .</p>
<p>2.4.
Клиент вправе:</p>
<p>2.4.1.
Требовать от Экспедитора полного и надлежащего исполнения всех обязательств, возложенных в соответствии с
настоящим договором.</p>
<p>2.4.2.
На основании Заявки воспользоваться дополнительными транспортно-экспедиционными услугами Экспедитора.</p>
<p>2.4.3.
При наличии вопросов, замечаний, пожеланий или предложений касающихся услуг, качества обслуживания и
организации работы, обратиться к Экспедитору посредством отправки сообщения на почту: <a href="mailto:sale@term-spb.ru">sale@term-spb.ru</a> .
Экспедитор гарантирует рассмотрение каждого обращения и предоставление Клиенту ответа в кратчайшие сроки.</p>
<p>2.5.
Клиент гарантирует, что:</p>
<p>2.5.1.
Информация о свойствах и характере груза, родовом наименовании груза, условиях его перевозки, маркировке,
весе, объеме, объявленной стоимости, контактах Грузополучателя является достоверной.</p>
<p>2.5.2.
Груз, переданный Экспедитору, не относится к категории грузов:
<br>- запрещенных к перевозке действующим законодательством РФ;
<br>- опасных, в том числе легковоспламеняющихся, взрывоопасных, содержащих едкие, ядовитые вещества;</p>
<p>2.5.3.
Содержимое груза соответствует заявленному в поручении Экспедитору (предоставляемым сопроводительным
документам на груз).</p>
<p>2.5.4.
Распоряжается грузом на законных основаниях.</p>
<p>2.5.5.
Грузоотправитель/Грузополучатель являются надлежащими представителями Клиента, уведомлены об условиях и
порядке оказания услуг.</p>

<h2>3. Порядок расчетов</h2>
<p>3.1.
Расчет оплаты за транспортно-экспедиционные услуги производится по тарифам Экспедитора, действующим на
момент приема груза. При определении стоимости перевозки принимается во внимание система измерения (по
объему или по весу) определяющая наиболее высокую стоимость перевозки груза. Порядок определения стоимости
услуг Экспедитора при несоответствии фактического веса (объема) предъявленного груза весу (объему) груза,
указанному Клиентом в Заявке, определяется с учетом следующего:
<br>- если фактический вес или объем груза окажется меньше веса или объема груза, указанного в Заявке, стоимость
услуг по организации получения груза рассчитывается и оплачивается Клиентом исходя из веса или объема груза,
указанного в Заявке. Данное правило распространяется и на иные параметры грузов (например, высота, длина груза
по полу транспортного средства, в случаях невозможности осуществления многоярусной загрузки), исходя из
которых осуществляется расчет стоимости получения груза, согласно тарифам Экспедитора;
<br>- если фактический вес или объем груза окажется больше веса или объема груза, указанного в Заявке, стоимость
услуг по организации получения груза рассчитывается и оплачивается Клиентом исходя из фактического веса или
объема груза, при наличии технической возможности отправки такого груза (в том числе совместно с грузами иных
клиентов по предстоящим на этот день Заявкам);
<br>- если действительная масса, объем, размеры, принятого к перевозке груза превышают массу, объем, размеры, по
которым была раньше определена плата за перевозку, Клиент обязан произвести доплату в соответствии с
тарифами Экспедитора.</p>
<p>3.2.
Счет Экспедитора подлежит оплате в течение 3 (трех) банковских дней с даты приема груза к перевозке. В случае
неоплаты Клиентом выставленного счета в установленные сроки Экспедитор имеет право отказаться от
предоставления услуг, и/или удерживать находящийся в его распоряжении груз в порядке, предусмотренном п.
2.2.4. настоящего договора. При организации Экспедитором перевозок авиационным и/или железнодорожными видами транспорта отправка груза производится только после поступления денежных средств на расчетный счет
Экспедитора.</p>
<p>3.3.
Датой оплаты считается дата поступления денежных средств на расчетный счет Экспедитора. В случае
несвоевременной оплаты вознаграждения Экспедитору и возмещения понесенных им в интересах Клиента расходов
Клиент несет ответственность в виде уплаты неустойки в размере 0,1 % (одной десятой процента) вознаграждения
Экспедитору и понесенных им в интересах Клиента расходов за каждый день просрочки исполнения обязательства.</p>
<p>3.4.
Оплата Клиентом аванса в счет неопределенного количества услуг Экспедитора в будущем, возможна без
выставления Экспедитором счета на сумму аванса. Стороны определили, что денежные средства, полученные от
Клиента в виде аванса или переплаты, не являются коммерческим кредитом. При отсутствии на момент выдачи
груза необходимой оплаты, выдача груза приостанавливается до момента поступления необходимых средств на
расчетный счет Экспедитора. Неполучение Клиентом счета не освобождает Клиента от обязательства по оплате
услуг Экспедитора в срок.</p>
<p>3.5.
Расходы, связанные с платным въездом для транспортных средств на территории складских комплексов, где
расположены терминалы Экспедитора (пункты приема/выдачи груза), оплачиваются Экспедитором за счет Клиента
и подлежат возмещению последним при предъявлении ему соответствующих платежных документов.</p>
<p>3.6.
Клиент обязан в течение 3-х рабочих дней после получения груза подписать акт об оказанных услугах либо
предоставить Экспедитору мотивированный отказ от подписания акта. Не подписание акта либо не предоставление
в указанный срок мотивированного отказа от его подписания является фактом признания надлежащего исполнения
Экспедитором своих обязательств в полном объеме. Акт оказанных услуг, направленный Экспедитором по
электронной почте, считается полученным Клиентом в день его направления Клиенту.</p>
<p>3.7. Экспедитор вправе изменять тарифы на последующие перевозки и дополнительные услуги в одностороннем
порядке с обязательным уведомлением посредством публикации новых тарифов на официальном сайте компании
<a href="https://term-spb.ru/">https://term-spb.ru/</a> . С момента размещения Экспедитором тарифов на сайте обязанность Экспедитора по
уведомлению Клиента считается исполненной</p>
<h2>4. Ответственность сторон</h2>
<p>4.1.
Экспедитор несет ответственность перед Клиентом в виде возмещения реального ущерба за утрату, недостачу или
повреждение (порчу) груза после принятия его Экспедитором и до выдачи груза получателю, указанному в
накладной, либо уполномоченному им лицу, если не докажет, что утрата, недостача или повреждение (порча) груза
произошли вследствие обстоятельств, которые Экспедитор не мог предотвратить и устранение которых от него не
зависело, в следующих размерах:
<br>1) за утрату или недостачу груза, принятого Экспедитором для перевозки с объявлением ценности, в размере
объявленной ценности или части объявленной ценности, пропорциональной недостающей части груза;
<br>2) за утрату или недостачу груза, принятого Экспедитором для перевозки без объявления ценности, в размере
действительной (документально подтвержденной) стоимости груза или недостающей его части;
<br>3) за повреждение (порчу) груза, принятого Экспедитором для перевозки с объявлением ценности, в размере
суммы, на которую понизилась объявленная ценность, а при невозможности восстановления поврежденного груза в
размере объявленной ценности;
<br>4) за повреждение (порчу) груза, принятого Экспедитором для перевозки без объявления ценности, в размере
суммы, на которую понизилась действительная (документально подтвержденная) стоимость груза, а при
невозможности восстановления поврежденного груза в размере действительной (документально подтвержденной)
стоимости груза.</p>
<p>4.2.
В случае если Экспедитор докажет, что нарушение обязательства вызвано ненадлежащим исполнением договора
перевозки, ответственность Экспедитора перед Клиентом, определяется на основании правил, по которым перед
Экспедитором отвечает соответствующий перевозчик.</p>
<p>4.3.
Экспедитор несет ответственность за нарушение сроков доставки груза в виде уплаты неустойки в размере 1 %
(одного процента) от стоимости перевозки за каждые сутки просрочки, за исключением случаев, нарушения сроков
доставки груза по вине Клиента или вследствие обстоятельств непреодолимой силы.</p>
<p>4.4.
Экспедитор не несет ответственности за повреждение груза в случае, если Клиент не обеспечил надлежащую
упаковку, согласно государственным стандартам или техническим условиям, при отказе Клиента от упаковки груза
(приведения упаковки в состояние, обеспечивающие сохранность груза при его транспортировке) предложенной
Экспедитором, если им будут сделаны при принятии груза соответствующие отметки в документах к грузу либо
если Экспедитор не мог обнаружить указанные несоответствия при визуальном осмотре. Ответственность за все
последствия неправильной внутренней упаковки грузов (бой, поломку, деформацию, течь и т.д.) несет Клиент.</p>
<p>4.5.
Экспедитор не производит сверку груза по соответствию наименования, количества и качества вложений
(содержимого) по сопроводительной документации, а также не проверяет содержимое на наличие явных или
скрытых дефектов, и не несет ответственности за выявившееся в процессе транспортировки и/или приема/выдачи
груза несоответствия, в том числе за внутритарную недостачу/порчу при целостности наружной упаковки;</p>
<p>4.6.
Клиент обязуется возмещать все убытки (в том числе по уплате штрафов), причиненные Экспедитору, в связи:
<br>– с неисполнением обязанностей по предоставлению информации, указанной в настоящем Договоре или в Заявках
Клиента;
<br>– с непредъявлением груза к перевозке, холостым пробегом транспортного средства, в размере стоимости услуги
получения или доставки груза от терминала Экспедитора, исчисляемой на основании данных о грузе в Заявке или
Накладной, по тарифам Экспедитора;
<br>– с отказом лица, указанного Клиентом в качестве грузополучателя и /или плательщика от получения груза и /или
оплаты счетов Экспедитора.– в связи с причинением ущерба имуществу Экспедитора и/или третьих лиц или другим
несоответствием тары или содержимым груза, предоставленного Клиентом к перевозке.</p>
<h2>5. Непреодолимая сила (форс-мажор)</h2>
<p>5.1.
Стороны освобождаются от ответственности за неисполнение или ненадлежащее исполнение обязательств по
настоящему договору, в случае наступления обстоятельств, прямо или косвенно препятствующих исполнению
настоящего договора, то есть таких обстоятельств, которые независимы от воли Сторон, не могли быть ими
предвидены в момент заключения настоящего договора и предотвращены разумными средствами при их
наступлении. К таким обстоятельствам, в том числе, относятся: война, военные действия, восстание, эпидемия,
землетрясение, наводнение, пожары, стихийные бедствия, акты органов государственной власти, непосредственно
влияющие на исполнение настоящего договора. Действие обстоятельств непреодолимой силы должно быть
подтверждено соответствующим актом, составленным законным путем уполномоченным органом ТПП, либо
официальными публикациями на сайтах новостных информационных агентств.</p>
<h2>6. Порядок предъявления претензий и разрешение споров</h2>
<p>6.1.
Взаимоотношения сторон неурегулированные настоящим договором, регламентируются действующим
законодательством Российской Федерации.</p>
<p>6.2.
Споры и разногласия, возникшие в связи с исполнением настоящего договора, рассматриваются в Арбитражном
суде города Санкт – Петербурга, либо в Красногвардейском районном суде города Санкт-Петербурга.</p>
<p>6.3.
До предъявления Экспедитору иска Клиент обязан предъявить претензию. К претензии в обязательном порядке
прилагаются документы, подтверждающие право на предъявление претензии и документы, подтверждающие
количество и стоимость груза, принятого к перевозке, в подлиннике или засвидетельствованные в установленном
порядке их копии.</p>
<p>6.4.
Экспедитор должен рассмотреть претензию Клиента в течение 30 календарных дней с момента ее получения.</p>
<p>6.5.
Если в момент получения груза Клиент (грузополучатель) не сделал на документе, подтверждающем факт вручения
груза, отметки об утрате, повреждении или недостаче содержимого, либо нарушения иных обязательств,
Экспедитор вправе считать, если не будет доказано иное, что Клиент (грузополучатель) получил груз
неповрежденным и в полном объеме. Акт о выявленной недостаче, порче груза, составленный в отсутствии
Экспедитора не является основанием для выставления претензии и не может быть принят к рассмотрению.</p>
<p>6.6.
Возмещение ущерба за повреждение грузов, не подлежащих восстановлению в полном объеме стоимости груза,
осуществляется только после передачи поврежденного груза Экспедитору.</p>
<h2>7. Срок действия договора и прочие положения</h2>
<p>7.1. Настоящий Договор вступает в силу с момента его заключения (акцепта оферты)
исполнении Сторонами своих обязательств, предусмотренных Договором.</p>
<p>7.2. Все приложения к настоящему Договору должны заключаться в письменной форме и подписываться уполномоченными
представителями Сторон, за исключением тарифов Экспедитора, утверждаемых Экспедитором в одностороннем
порядке.</p>
<p>7.3. Клиент дает безусловное согласие на обработку и хранение предоставленных в связи с исполнением настоящего
договора персональных данных, на получение информации об услугах Экспедитора, информации о статусе и
местонахождении груза, оповещениях о проводимых акциях, мероприятиях, скидках, их результатах, в виде смс-
сообщений на номер телефона и/или сообщений на электронный адрес Клиента, указанные им в форме обращения
или в бланке заявки (поручения Экспедитору). При предоставлении Клиентом персональных данных иных лиц,
Клиент гарантирует, что согласие таких лиц на предоставление их персональных данных Экспедитору Клиентом
получено и несет ответственность в случае предъявления каких-либо претензий Экспедитору вследствие не
соблюдения данного условия. Настоящее согласие является бессрочным и может быть отозвано Клиентом в любой
момент посредством направления Экспедитору уведомления. Согласие будет считаться отозванным, в течение 5
(пяти) рабочих дней с момента получения уведомления.</p>
<p>7.4. К отношениям по договору с участием Клиента - физического лица, использующего услуги Экспедитора для личных,
семейных, домашних и иных нужд, не связанных с осуществлением Клиентом предпринимательской деятельности,
положения настоящего Договора применяются в части не противоречащей законодательству Российской
Федерации о защите прав потребителей.</p>
<p>7.5. Права требования по настоящему Договору могут быть переданы Клиентом третьим лицам только при условии
предварительного письменного согласия Экспедитора.</p>
<p>7.6. Условия Договора не распространяются на организацию услуг по перевозке грузов выделенным транспортом, при
котором Клиенту (грузоотправителю) под погрузку подается транспортное средство (весь грузовой отсек
транспортного средства), вместимостью и по тоннажу, заявленному Клиентом, для доставки груза
грузополучателю, отдельным транспортом.</p>
<p>7.7. Экспедитор оставляет за собой право в любое время вносить изменения в условия настоящего публичного договора-
оферты, если время вступления изменений в силу специально не оговорено, они начинают свое действие с момента
опубликования их на сайте Экспедитора: <a href="https://term-spb.ru/">https://term-spb.ru/</a> .</p>
<p>7.8. По письменному требованию Клиента Экспедитор может оформить печатную версию
подписями Сторон, равному по юридической силе настоящему публичному Договору.</p>

<h2>8. Реквизиты Экспедитора:</h2>
<p>ООО «ТЕРМИНАЛ СПБ»
<br>Адрес: 195273,г.Санкт-Петербург,ВН.ТЕР.Г. МУНИЦИПАЛЬНЫЙ
<br>ОКРУГ ПОЛЮСТРОВО, ПР-КТ ПИСКАРЁВСКИЙ,Д. 63, ЛИТЕРА
<br>А,ОФИС 148,ПОМ. 3-Н
<br>ОГРН 1237800053545
<br>ИНН 7806609898
<br>КПП 780601001
<br>р/сч 40702810832000017170
<br>Банк: Филиал «Санкт-Петербургский» АО «Альфа-Банк»
<br>БИК: 044030786
<br>к/сч 30101810600000000786
</p>`,
        },
    },
    getters: {
        storePolicy(state){
            return state.policy
        },
        storeOferta(state){
            return state.oferta
        }
    }
}