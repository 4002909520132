<template>
  <a href="tel:+79139170705">+7 913 917-07-05</a>
</template>

<script>
export default {
  name: "TelPrimary",
}
</script>

<style scoped>

</style>