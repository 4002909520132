<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="180" height="45" viewBox="0 0 180 45" fill="none">
    <path d="M27.8059 41.5087V9.41122H44.1582C44.8482 7.07911 47.8151 1.93192 54.1628 0H0V9.41122H15.828V41.5087H27.8059Z" fill="#FFD600"/>
    <path d="M63.2 0H86.7709V9.41122H63.6706C59.1789 9.41122 57.2538 11.5929 57.2538 14.5446C57.2538 17.4535 59.1789 19.678 63.6706 19.678H86.7709V29.0892H63.2C50.9227 29.0892 44.8481 23.6136 44.8481 14.5446C44.8481 10.2668 46.3882 6.75897 49.511 4.06393C52.6338 1.3689 57.1683 0 63.2 0Z" fill="white"/>
    <path d="M88.472 29.0892V0H131.25V29.0892H119.272V9.41122H100.45V29.0892H88.472Z" fill="white"/>
    <path d="M132.963 0H175.741V9.41122H144.941V12.8335H162.181C165.731 12.8335 168.469 13.1757 170.437 13.8174C174.244 15.058 176.169 17.4963 176.169 21.0897C176.169 24.8114 174.073 27.2498 169.881 28.4048C168.212 28.8753 166.202 29.0892 163.763 29.0892H132.963V0ZM161.71 16.2557H144.941V19.678H161.71C163.635 19.678 164.619 19.1219 164.619 18.0097C164.619 16.8546 163.635 16.2557 161.71 16.2557Z" fill="white"/>
    <path d="M38.568 38.0175H34.1964C34.4241 38.4273 34.8187 38.6247 35.4107 38.6247H42.0745V41.5087H35.2589C31.8132 41.5087 30.0828 39.9604 30.0828 37.4103C30.0828 36.2111 30.523 35.2245 31.3882 34.4655C32.2686 33.6914 33.5588 33.3119 35.2589 33.3119H38.568C39.5547 33.3119 40.2378 33.403 41.0119 33.7065C41.7861 33.9949 42.2263 34.6477 42.2263 35.6191C42.2263 37.213 41.0119 38.0175 38.568 38.0175ZM38.1886 36.196H35.4107C34.8187 36.196 34.4241 36.3933 34.1964 36.8031H38.1886C38.5073 36.8031 38.6591 36.6969 38.6591 36.4995C38.6591 36.3022 38.5073 36.196 38.1886 36.196Z" fill="#FFD600"/>
    <path d="M42.6864 45V33.3119H50.0332C51.7333 33.3119 53.0236 33.6914 53.8888 34.4655C54.7692 35.2245 55.2094 36.2111 55.2094 37.4103C55.2094 39.9604 53.4789 41.5087 50.0332 41.5087H46.633V45H42.6864ZM46.633 36.196V38.6247H49.8814C51.7181 38.6854 51.7181 36.1352 49.8814 36.196H46.633Z" fill="#FFD600"/>
    <path d="M55.6719 41.5087V33.3119H60.5748L62.8821 36.4236L65.1894 33.3119H70.0923V41.5087H66.1457V37.6835L64.6277 39.672H61.1365L59.6185 37.6835V41.5087H55.6719Z" fill="#FFD600"/>
    <path d="M70.703 41.5087V33.3119H74.6497V37.1219L79.1731 33.3119H84.0609V41.5087H80.1143V37.7746L75.4997 41.5087H70.703Z" fill="#FFD600"/>
    <path d="M94.0781 35.9683V33.3119H98.0247V41.5087H94.0781V38.8524H88.6135V41.5087H84.6669V33.3119H88.6135V35.9683H94.0781Z" fill="#FFD600"/>
    <path d="M110.486 36.196V41.5087H102.137C101.151 41.5087 100.467 41.4177 99.6933 41.1293C98.9191 40.8257 98.4789 40.173 98.4789 39.2015C98.4789 37.6076 99.6933 36.8031 102.137 36.8031H106.539C106.539 36.454 106.266 36.196 105.78 36.196H99.0861V33.3119H106.281C109.15 33.3119 110.486 34.4807 110.486 36.196ZM102.517 38.6247H106.539V38.0175H102.517C102.198 38.0175 102.046 38.1237 102.046 38.3211C102.046 38.5184 102.198 38.6247 102.517 38.6247Z" fill="#FFD600"/>
    <path d="M113.556 41.5087H110.946V38.6247H111.72C112.145 38.6247 112.403 38.4729 112.494 38.1541L113.754 33.3119H124.303V41.5087H120.357V36.196H117.2L116.228 39.7479C115.909 40.9167 115.014 41.5087 113.556 41.5087Z" fill="#FFD600"/>
  </svg>
</template>

<script>
export default {
  name: "LogoBase",
  props:{
    isPrimary:{
      type: Boolean,
      default(){
        return true
      }
    }
  }
}
</script>

<style scoped>

</style>