<template>
<div>
  <section id="home" class="section main about-us">
    <div class="wrap main__container">
      <div class="main__content">
        <div class="main__caption">Крупнейший в РФ транспортный дискаунтер</div>
        <div class="main__title">Перевозка попутных грузов по России с тарифами <span>ниже рыночных на 20-30%</span></div>
        <div class="main__list">
          <div class="main__item">
            <img src="@/shared/assets/images/icon-6.png" alt="Экономия" class="main__item__image">
            <div class="main__item__description">Экономно за счет недогруженных машин</div>
          </div>
          <div class="main__item">
            <img src="@/shared/assets/images/icon-4.png" alt="Скорость" class="main__item__image">
            <div class="main__item__description">Быстро и без ожидания полной загрузки машины</div>
          </div>
          <div class="main__item">
            <img src="@/shared/assets/images/icon-5.png" alt="Качество" class="main__item__image">
            <div class="main__item__description">Качественно перевозим грузы</div>
          </div>
        </div>
      </div>
      <div class="main__order" id="orderCreate">
        <template v-if="!isSuccessOrderCreate">
          <div class="main__order__title">Расчет стоимости перевозки</div>
          <div class="main__order__description">Оставьте заявку на расчет стоимости и наш менеджер свяжется с Вами</div>
        </template>
        <div class="main__order__form">
          <OrderCreateSuccess v-if="isSuccessOrderCreate" @isSuccess="onSuccessOrderCreate"/>
          <order-create-form v-else :is-cut="true" @isSuccess="onSuccessOrderCreate">
            <FormCallback v-show="!isOrderCreate" ref="formCallback"/>
            <FormCargo v-show="isOrderCreate"/>
            <template #buttonMore><div  class="main__order__form__more" @click="onClickFormMore">{{ isOrderCreate ? '- Скрыть информацию о грузе' : '+ Добавить информацию о грузе'}}</div></template>
          </order-create-form>
        </div>
      </div>
    </div>
  </section>

  <section id="aboutUs" class="section section--grey about-us">
    <div class="wrap">
      <h2 class="section__title">О компании</h2>
      <div class="about-us__list">
        <div class="about-us__item">
          <img src="@/shared/assets/images/icon.png" alt="Стратегия" class="about-us__item__image">
          <div>
            <div class="about-us__item__title">Стратегия</div>
            <div class="about-us__item__description">Наша стратегия заключается в использовании недогруженных машин и транспорта, идущего полупустым из обратных рейсов</div>
          </div>
        </div>
        <div class="about-us__item">
          <img src="@/shared/assets/images/icon-1.png" alt="Тарифы" class="about-us__item__image">
          <div>
            <div class="about-us__item__title">Тарифы</div>
            <div class="about-us__item__description">Эффективное использование ресурсов позволяет нам перевозить грузы по конкурентоспособным тарифам - ниже рыночных на 20-30%</div>
          </div>
        </div>
        <div class="about-us__item">
          <img src="@/shared/assets/images/icon-2.png" alt="Качество" class="about-us__item__image">
          <div>
            <div class="about-us__item__title">Качество</div>
            <div class="about-us__item__description">Мы направляем все усилия на предоставление  качественных услуг по перевозке грузов, с фокусом на экономии времени и средств</div>
          </div>
        </div>
      </div>
    </div>
  </section>


  <section id="faq" class="section faq">
    <div class="wrap">
      <h2 class="section__title">Часто задаваемые вопросы</h2>
      <div class="faq__list">
        <accordion-base
            v-for="item in faq" :key="item.id"
            :id="item.id"
            class="faq__item"
            @changeStatus="onChangeStatusFAQ"
        >
          <template #action>
            <div class="faq__item__title">
              <IconBase v-if="!item.isActive" type="plus"/>
              <IconBase v-else type="cancel"/>
              {{ item.title }}
            </div>
          </template>
          <template #area>
            <div class="faq__item__description" v-html="item.description"></div>
          </template>
        </accordion-base>
      </div>
    </div>
  </section>
</div>
</template>

<script>
import AccordionBase from "@/shared/ui/AccordionBase";
import IconBase from "@/shared/ui/IconBase";
import OrderCreateForm from "@/features/OrderCreateForm";
import OrderCreateSuccess from "@/features/OrderCreateSuccess";
import FormCallback from "@/features/FormCallback";
import FormCargo from "@/features/FormCargo";
import {scrollToId, scrollByHash} from "@/shared/methods/scroll";
export default {
  name: "MainPage",
  components: {FormCargo, FormCallback, OrderCreateSuccess, OrderCreateForm, IconBase, AccordionBase},
  data(){
    return{
      isOrderCreate: false,
      isSuccessOrderCreate: false,
      faq:[
        {
          id: '1',
          title: 'Куда сдавать и где забирать груз?',
          description: '<p>Мы принимаем и выдаем груз на терминалах наших партнеров. Если мы принимаем Ваш груз к перевозке, мы указываем адрес терминала в городе отправления и городе доставки. Также мы можем оказывать услуги по забору груза непосредственно у отправителя и доставке до двери получателя.</p>',
          isActive: false
        },
        {
          id: '2',
          title: 'Есть ли у нас свой парк автомобилей?',
          description: '<p>Мы сотрудничаем с транспортными компаниями – собственниками транспорта. Надежность наших партнеров обеспечивается и гарантируется за счет внимательной проверки и аккредитации наших партнеров и долгосрочного сотрудничества с ними.</p>',
          isActive: false
        },
        {
          id: '3',
          title: 'Насколько дешевле мы везем груз, чем обычная транспортная компания',
          description: '<p>Обычно стоимость перевозки дешевле на 15-20% стандартных условий перевозчика. Достигается это за счет того, что Терминал СПБ является крупным заказчиком для партнеров.</p>',
          isActive: false
        },
        {
          id: '4',
          title: 'Мы работаем с физическими или юридическими лицами?',
          description: '<p>Мы работаем с любыми отправителями - как с частными лицами, так и с организациями.</p>',
          isActive: false
        }
      ],
    }
  },
  mounted() {
    const hash = this.$route.hash
    if(hash){
      scrollByHash(hash)
      setTimeout(() => {
        this.removeHash()
      }, 2)
    }
    window.addEventListener('click', this.onClickNavigationItem);
  },
  methods:{
    removeHash: function (){
      location.hash = ''
    },

    onClickNavigationItem: function (e){
      const el = e.target
      const item = 'navigation__item'
      if(el.classList.contains(item)){
        scrollToId(el)
      }
    },

    onClickFormMore: function (){
      this.isOrderCreate = !this.isOrderCreate
    },

    onSuccessOrderCreate: function (boolean){
      this.isSuccessOrderCreate = boolean
      this.$refs.formCallback.toReset()
    },
    onChangeStatusFAQ(id, status){
      const array = this.faq
      for(let i = 0; i < array.length; i++){
        if(array[i].id === id){
          array[i].isActive = status
        }
      }
      this.faq = array
    },
  }
}
</script>

<style lang="stylus">
@import "@/shared/styl/variables.styl"
.main
  &__caption
    font-size 18px
    color $dont-accent
    margin-bottom 20px
  &__title
    font-size 32px
    font-weight 700
    line-height 130%
    margin-bottom 40px
    span
      color $accent
    @media screen and (min-width 1220px)
      margin-bottom 50px
      font-size 40px
  &__content
    margin-bottom 120px
    @media screen and (min-width 1220px)
      margin-bottom 0
  &__container
    @media screen and (min-width 1220px)
      gap 140px
      display flex
      justify-content space-between
      align-items flex-start
  &__item
    display flex
    align-items flex-start
    gap 20px
    font-size 15px
    color $main
    @media screen and (min-width 1220px)
      max-width 260px
  &__list
    display flex
    flex-direction column
    gap 30px
    @media screen and (min-width 768px)
      flex-direction row
      flex-wrap wrap
      justify-content space-between
      align-items flex-start

  &__order
    padding 30px 25px
    border-radius 10px 10px 10px 100px
    background-color $main
    color $white
    @media screen and (min-width 1220px)
      padding 40px
      min-width 420px
    &__title
      font-size 24px
      font-weight 700
      margin-bottom 10px
      text-align center
    &__description
      text-align center
      font-size 15px
      font-weight 400
    &__form__more
      text-align center
      cursor pointer

.section
  padding 60px 0
  @media screen and (min-width 768px)
    padding 80px 0
  @media screen and (min-width 1220px)
    padding 100px 0
  &--grey
    background-color #F6F6F6
  &--pb-none
    padding-bottom 0
  &--center
    text-align center
  &__title
    fontDefault()
    font-size 32px
    margin-bottom 30px
    @media screen and (min-width 1300px)
      font-size 40px
      margin-bottom 40px
.faq
  background-color $white
  &__list
    margin-top 20px
  &__item
    border-bottom 1px solid $dont-accent
    &__title
      display flex
      align-items center
      gap 20px
      padding 20px 0
      font-size 16px
      font-weight 600
    &__description
      color $main
      padding-bottom 20px
      padding-left 40px

.about-us
  &__list
    display flex
    flex-direction column
    gap 30px
    @media screen and (min-width 1300px)
      flex-direction row
      justify-content space-between
      align-items flex-start
  &__item
    display flex
    flex-direction row
    align-items flex-start
    gap 20px
    @media screen and (min-width 1300px)
      flex-direction column
    &__title
      font-size 24px
      font-weight 700
      margin-bottom 20px
      color $main
    &__description
      font-size 15px
      font-weight 400
      color $main-gray
</style>