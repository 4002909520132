<template>
  <div class="icon-base" v-html="icon"></div>
</template>

<script>
export default {
  name: "IconBase",
  props:{
    type:{
      type: String
    }
  },
  computed:{
    icon(){
      // Поинт
      return this.type === 'point' ? `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><path fill="currentColor" d="M12 9.633c-.505 0-.99-.193-1.347-.537a1.8 1.8 0 0 1-.558-1.296c0-.486.201-.952.558-1.296A1.943 1.943 0 0 1 12 5.967c.505 0 .99.193 1.347.537a1.8 1.8 0 0 1 .558 1.296c0 .24-.05.48-.145.702a1.833 1.833 0 0 1-.413.594c-.177.17-.387.306-.618.398a1.97 1.97 0 0 1-.729.14Zm0-6.966A5.44 5.44 0 0 0 8.23 4.17 5.038 5.038 0 0 0 6.667 7.8c0 3.85 5.333 9.533 5.333 9.533s5.333-5.683 5.333-9.533a5.038 5.038 0 0 0-1.562-3.63 5.44 5.44 0 0 0-3.77-1.503Z"/></svg>`
      // Длинная стрелка вправо
      : this.type === 'arrowLink' ? `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="9" viewBox="0 0 16 9" fill="none"><path d="M15.3536 4.85355C15.5488 4.65829 15.5488 4.34171 15.3536 4.14645L12.1716 0.964466C11.9763 0.769204 11.6597 0.769204 11.4645 0.964466C11.2692 1.15973 11.2692 1.47631 11.4645 1.67157L14.2929 4.5L11.4645 7.32843C11.2692 7.52369 11.2692 7.84027 11.4645 8.03553C11.6597 8.2308 11.9763 8.2308 12.1716 8.03553L15.3536 4.85355ZM0 5H15V4H0V5Z" fill="currentColor"/></svg>`
      // Телефон
      : this.type === 'tel' ? `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><path fill="currentColor" d="m12.667 13.239 1.12-1.12a1.095 1.095 0 0 1 1.169-.24l1.364.545a1.09 1.09 0 0 1 .68 1v2.5a1.086 1.086 0 0 1-1.14 1.075C6.3 16.404 4.372 8.305 4.008 5.205A1.085 1.085 0 0 1 5.09 4h2.414a1.08 1.08 0 0 1 1 .68l.544 1.365a1.08 1.08 0 0 1-.235 1.17l-1.12 1.12s.645 4.364 4.973 4.904Z"/></svg>`
      // Почта
      : this.type === 'mail' ? `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><path fill="currentColor" d="M17 7.925v6.15A1.93 1.93 0 0 1 15.066 16H4.934a1.939 1.939 0 0 1-1.367-.564A1.92 1.92 0 0 1 3 14.075v-6.15l6.778 3.71a.463.463 0 0 0 .444 0L17 7.925ZM15.066 5c.505 0 .99.197 1.35.548.362.35.571.828.583 1.33L10 10.71 3 6.878l.001-.033c.021-.496.234-.965.594-1.31A1.94 1.94 0 0 1 4.935 5h10.13Z"/></svg>`
      // Плюс
      : this.type === 'plus' ? `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none"><path stroke="#FFD600" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 10.5V6m0 4.5V15m0-4.5h4.5m-4.5 0H5.5"/></svg>`
      // Зеленый крестик
      : this.type === 'cancel' ? `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
  <rect y="0.5" width="20" height="20" rx="10" fill="#FFD600"/>
  <path d="M14.4999 10.5001H9.99988H5.49986" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`
      // Крестик
      : this.type === 'close' ? `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.5001 16.1133L19.0357 19.5349C19.1536 19.6451 19.3115 19.706 19.4753 19.7047C19.6392 19.7033 19.796 19.6397 19.9118 19.5275C20.0277 19.4154 20.0934 19.2637 20.0949 19.1051C20.0963 18.9465 20.0333 18.7937 19.9194 18.6797L16.3838 15.2581L19.9194 11.8365C20.0333 11.7224 20.0963 11.5696 20.0949 11.4111C20.0934 11.2525 20.0277 11.1008 19.9118 10.9886C19.796 10.8765 19.6392 10.8129 19.4753 10.8115C19.3115 10.8101 19.1536 10.8711 19.0357 10.9813L15.5001 14.4028L11.9644 10.9813C11.846 10.8738 11.6891 10.8152 11.5268 10.8177C11.3645 10.8203 11.2096 10.8838 11.0948 10.9949C10.9801 11.106 10.9145 11.256 10.912 11.4131C10.9095 11.5701 10.9702 11.722 11.0813 11.8365L14.6163 15.2581L11.0807 18.6797C11.021 18.7354 10.9734 18.8022 10.9406 18.876C10.9079 18.9498 10.8906 19.0291 10.8899 19.1094C10.8892 19.1898 10.905 19.2694 10.9364 19.3437C10.9679 19.4181 11.0143 19.4856 11.073 19.5424C11.1316 19.5992 11.2014 19.6441 11.2782 19.6745C11.355 19.7049 11.4373 19.7202 11.5203 19.7195C11.6033 19.7188 11.6853 19.7021 11.7616 19.6704C11.8378 19.6387 11.9068 19.5927 11.9644 19.5349L15.5001 16.1133Z" fill="currentColor"/></svg>`
      // Лоадер
      : this.type === 'loader' ? `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24 15C24 16.9949 23.3372 18.9332 22.1158 20.5105C20.8944 22.0877 19.1836 23.2145 17.2522 23.7137C15.3208 24.2129 13.2782 24.0562 11.4455 23.2684C9.61281 22.4805 8.0938 21.1061 7.12717 19.361C6.16053 17.616 5.80106 15.5992 6.10524 13.6277C6.40942 11.6561 7.36 9.84152 8.80763 8.46896C10.2553 7.0964 12.1179 6.24369 14.1028 6.04483C16.0878 5.84597 18.0825 6.31222 19.7737 7.37031L18.9516 8.68415C17.5517 7.80826 15.9004 7.4223 14.2573 7.58691C12.6142 7.75153 11.0723 8.4574 9.87396 9.5936C8.67561 10.7298 7.88872 12.232 7.63692 13.864C7.38512 15.4961 7.68269 17.1655 8.48287 18.6101C9.28305 20.0546 10.5405 21.1923 12.0576 21.8445C13.5747 22.4967 15.2655 22.6264 16.8643 22.2132C18.4632 21.7999 19.8794 20.8672 20.8905 19.5616C21.9015 18.2559 22.4502 16.6514 22.4502 15H24Z" fill="#fff"/></svg>`
      : `<svg></svg>`

    }
  }
}
</script>

<style lang="stylus">
.icon-base
  display flex
  justify-content center
  align-items center
  width 20px
  height 20px
  transition 200ms
</style>