<template>
  <span class="link-base"><slot/></span>
</template>

<script>
export default {
  name: "LinkBase"
}
</script>

<style lang="stylus">
@import "@/shared/styl/variables.styl"
.link-base
  color $link
  cursor pointer
</style>