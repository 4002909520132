<template>
  <header class="header" id="header">
    <div ref="body">
      <div class="header__body">
        <div class="wrap">
          <router-link  :to="{name: 'home'}"><LogoBase class="header__body__logo" :is-primary="true"/></router-link>
          <MenuList v-show="isDesktop" class="header__menu-list wrap"/>
          <ContactList v-show="isDesktop" class="header__contact-list"/>
          <to-order v-show="isTablet || isDesktop" class="header__order"><button-text-secondary>Заказать грузоперевозку</button-text-secondary></to-order>
          <div v-if="!isDesktop" class="header__menu__button" @click="isActiveMenu = !isActiveMenu">
            <svg v-if="!isActiveMenu" xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 30 20" fill="none">
              <path d="M28.4167 8H1.58333C0.708883 8 0 8.63799 0 9.425V9.575C0 10.362 0.708883 11 1.58333 11H28.4167C29.2911 11 30 10.362 30 9.575V9.425C30 8.63799 29.2911 8 28.4167 8Z" fill="#FFD600"/>
              <path d="M28.4167 17H1.58333C0.708883 17 0 17.638 0 18.425V18.575C0 19.362 0.708883 20 1.58333 20H28.4167C29.2911 20 30 19.362 30 18.575V18.425C30 17.638 29.2911 17 28.4167 17Z" fill="#FFD600"/>
              <path d="M28.4167 0H1.58333C0.708883 0 0 0.637994 0 1.425V1.575C0 2.36201 0.708883 3 1.58333 3H28.4167C29.2911 3 30 2.36201 30 1.575V1.425C30 0.637994 29.2911 0 28.4167 0Z" fill="#FFD600"/>
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
              <path d="M1.99867 20.0936L20.9727 1.11959C21.591 0.501256 22.5434 0.45113 23.0999 1.00763L23.206 1.11369C23.7625 1.67019 23.7123 2.62258 23.094 3.24091L4.11999 22.2149C3.50166 22.8333 2.54927 22.8834 1.99278 22.3269L1.88671 22.2208C1.33021 21.6643 1.38034 20.7119 1.99867 20.0936Z" fill="#FFD600"/>
              <path d="M23.0939 20.0936L4.11983 1.11959C3.5015 0.501256 2.54911 0.45113 1.99262 1.00763L1.88655 1.11369C1.33005 1.67019 1.38018 2.62258 1.99851 3.24091L20.9725 22.2149C21.5909 22.8333 22.5433 22.8834 23.0998 22.3269L23.2058 22.2208C23.7623 21.6643 23.7122 20.7119 23.0939 20.0936Z" fill="#FFD600"/>
            </svg>
          </div>
        </div>
      </div>
    </div>

    <teleport to="body">
      <div v-if="isActiveMenu">
        <div ref="modal" class="header__menu-modal" :style="`height: ${modalHeight()}px; top:${headerHeight()}px`">
          <MenuList @clicked="isActiveMenu = false" v-show="!isDesktop" class="header__menu-modal__menu-list wrap"/>
          <ContactList class="header__contact-list header__menu-modal__contact-list"/>
        </div>
      </div>
    </teleport>
  </header>
</template>

<script>
import ToOrder from "@/features/ToOrder";
import LogoBase from "@/shared/ui/LogoBase";
import ContactList from "@/widgets/ContactList";
import {mapGetters} from "vuex";
import MenuList from "@/widgets/MenuList";
import ButtonTextSecondary from "@/shared/ui/ButtonTextSecondary";

export default {
  name: "TheHeader",
  components: {
    ButtonTextSecondary,
    MenuList,
    ToOrder,
    LogoBase,
    ContactList
  },
  data(){
    return{
      isActiveMenu: false,
      height: 0
    }
  },
  computed:{
    ...mapGetters(['getWindowSize']),
    isDesktop(){
      return this.getWindowSize?.isDesktop
    },
    isTablet(){
      return this.getWindowSize?.isTablet
    },
    isMobile(){
      return this.getWindowSize?.isMobile
    },
  },
  mounted() {
    setTimeout(()=>{
      this.visibilityHeader()
    }, 1)
  },
  beforeUnmount() {
    const body = document.getElementById('app')
    body.style.paddingTop = `0px`
  },
  methods:{
    // Показать/скрыть header при скролле
    visibilityHeader: function (){

        let elY = 0;
        let scrollY = 0;
        const header = document.querySelector('#header');
        const body = document.getElementById('app')
        let height = this.height = header.offsetHeight;
        body.style.paddingTop = `${height}px`
        window.addEventListener("resize", () => {
          height = this.height = header.offsetHeight;
          body.style.paddingTop = `${height}px`
        })

        window.addEventListener("scroll", () => {
          const pos = window.pageYOffset;
          const diff = scrollY - pos;

          elY = Math.min(0, Math.max(-height, elY + diff));
          header.style.position = pos >= height || this.isActiveMenu ? 'fixed' : pos === 0 && !this.isActiveMenu ? 'absolute' : header.style.position;
          header.style.top = `${header.style.position === 'fixed' && !this.isActiveMenu ? elY : 0}px`;
          scrollY = pos;
        })

    },

    modalHeight(){
      return window.innerHeight - this.headerHeight()
    },
    headerHeight(){
      return this.$refs.body.clientHeight
    }
  }
}
</script>

<style lang="stylus">
@import "@/shared/styl/variables.styl"

.header
  width 100%
  z-index 3
  position absolute
  top 0
  left 0
  background-color $main
  box-shadow 0 4px 10px 0 rgba(0, 0, 0, 0.03)
  &__body
      padding-top 17px
      padding-bottom 15px
      > .wrap
        display flex
        justify-content space-between
        align-items center
        padding-left 10px
      &__logo
        margin-right 60px
      @media screen and (min-width 1300px)
        > .wrap
          padding-left 0

  &__contact-list
    color $white
    //display flex
    //flex-wrap wrap
    //justify-content space-around
    //align-items center
    gap 20px
    > *:first-child
      width 100%
      text-align center
      justify-content center
    @media screen and (min-width 768px)
      flex-wrap nowrap
      > *:first-child
        justify-content flex-start
        width auto
        margin-right auto
  &__order
    margin-left auto
    margin-right 20px
    @media screen and (min-width 1300px)
      margin-right 0
  &__preline
    padding-top 10px
    padding-bottom 14px
    border-bottom 1px solid $greyLight
    margin-bottom 5px
  &__menu-list
    //border-top 1px solid $greyLight
    padding-top 15px
    padding-bottom 15px
    display flex
    justify-content space-between
    align-items center
    margin-right auto!important
    margin-left 0!important
  &__menu-modal
    height 100vh
    position fixed
    display flex
    flex-direction column
    justify-content space-between
    align-items center
    gap 30px
    width 100%
    left 0
    background-color $main
    padding-top 60px
    padding-left 20px
    padding-right 20px
    @media screen and (min-width 768px)
      padding-top 250px
    &__contact-list
      padding-bottom 40px
      text-align center
      .item__content
        display block
        width 100%
</style>