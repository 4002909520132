import {createRouter, createWebHistory} from "vue-router"
import SinglePage from "@/pages/SinglePage";
import MainPage from "@/pages/MainPage";

const routes = [
    {name:'home', path: '/', component: MainPage},
    {name:'policy', path: '/page/:id', meta: { scrollToTop: true }, component: SinglePage,},
    {name:'oferta', path: '/page/:id',  meta: { scrollToTop: true }, component: SinglePage}
]

export const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to) {
        if(to.path !== '/' && !to.hash){
            // если не главная страница и нет якорной ссылки
            return { top: 0 }
        }
    },
})