<template>
  <div class="contact">
<!--    <item-icon-base>-->
<!--      <template v-slot:icon><IconBase type="point"/></template>-->
<!--      <template v-slot:content>г. МОСКВА, ш-се Волоколамское, д. 108, пом. 8Ц</template>-->
<!--    </item-icon-base>-->
    <item-icon-base v-if="hasTel">

      <template v-slot:content><span class="contact__item__title"><TelPrimary/></span></template>
    </item-icon-base>
    <item-icon-base>
      <template v-slot:content><span class="contact__item__title contact__item__title--secondary"><MailPrimary/></span></template>
    </item-icon-base>
  </div>
</template>

<script>
import ItemIconBase from "@/shared/ui/ItemIconBase";
// import IconBase from "@/shared/ui/IconBase";
import TelPrimary from "@/features/TelPrimary";
import MailPrimary from "@/features/MailPrimary";
// import LinkBase from "@/shared/ui/LinkBase";
export default {
  name: "ContactList",
  components: {MailPrimary, TelPrimary, ItemIconBase},
  props:{
    hasTel:{
      type: Boolean,
      default(){
        return true
      }
    }
  }
}
</script>

<style lang="stylus">
@import "@/shared/styl/variables.styl"
.contact
  color inherit
  &__item__title
    font-size 16px
    font-weight 700
    color $white
    &--secondary
      font-size 15px
      font-weight 400
      color $dont-accent
</style>