<template>
  <button-text-base
      :is-loading="isLoading"
      :is-disabled="isDisabled"
      class="button-text--primary"
  ><slot></slot></button-text-base>
</template>

<script>
import ButtonTextBase from "@/shared/ui/ButtonTextBase";
export default {
  name: "ButtonTextPrimary",
  components: {ButtonTextBase},
  props:{
    isLoading:{
      type:Boolean,
      default(){
        return false
      }
    },
    isDisabled:{
      type:Boolean,
      default(){
        return false
      }
    }
  }
}
</script>

<style lang="stylus">
@import "@/shared/styl/variables.styl"
.button-text--primary
  background-color $accent
  border 2px solid transparent
</style>