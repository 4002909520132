<template>
  <div v-if="isMainPage" class="to-order" data-id="orderCreate" @click.capture="onClick($el)">
    <slot/>
  </div>
  <router-link
      v-else
      class="to-order"
      to="/#orderCreate"
  >
    <slot/>
  </router-link>
</template>

<script>

import {scrollToId} from "@/shared/methods/scroll";

export default {
  name: "ToOrder",
  computed:{
    isMainPage(){
      return this.$route.path === '/'
    }
  },
  methods:{
    onClick: function (el){
      console.log(el)
      scrollToId(el)
      this.$emit('clicked')
    }
  },
}
</script>

<style lang="stylus">
.to-order
  cursor pointer
</style>