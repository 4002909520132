
import {store} from "@/app/store";
import {fetchPost, normalizeResponse} from "@/shared/methods/methodsApi";

export const postOrder = async () =>{
    const CALLBACK_URL = process.env.VUE_APP_BASEURL + process.env.VUE_APP_POST_ORDER;
    const response = await fetchPost(CALLBACK_URL, params()) // fetch
    return normalizeResponse(response)
    // console.log(params())
    // return {status: null}
}


export class Order{
    constructor(name, tel, mail, departure, destination, weight, volume, place) {
        this.name = name; // string
        this.phone = tel; // string
        this.email = mail; // string
        this.city_from = departure; // string
        this.city_to = destination; // string
        this.weight = weight; // number
        this.volume = volume; // number
        this.place = place; // number
    }
}

const params = () => {
    const params = new Order()
    params.name = store.getters.storeGetName('value')
    params.phone = store.getters.storeGetTel('value')
    params.email = store.getters.storeGetMail('value')
    params.city_from = store.getters.storeGetDeparture('value') ? store.getters.storeGetDeparture('value') : ''
    params.city_to = store.getters.storeGetDestination('value') ? store.getters.storeGetDestination('value') : ''
    params.weight = store.getters.storeGetWeight('value') ? Number(store.getters.storeGetWeight('value')).toFixed(1) : 0
    params.volume = store.getters.storeGetVolume('value') ? Number(store.getters.storeGetVolume('value')).toFixed(1) : 0
    params.place = store.getters.storeGetPlace('value') ? Math.ceil(Number(store.getters.storeGetPlace('value'))) : 0
    return params
}
