<template>
  <button-base
      :is-loading="isLoading"
      :is-disabled="isDisabled"
      class="button-text"
  ><slot></slot></button-base>
</template>

<script>
import ButtonBase from "@/shared/ui/ButtonBase";
export default {
  name: "ButtonTextBase",
  components: {ButtonBase},
  props:{
    isLoading:{
      type:Boolean,
      default(){
        return false
      }
    },
    isDisabled:{
      type:Boolean,
      default(){
        return false
      }
    }
  }
}
</script>

<style lang="stylus">
@import "@/shared/styl/variables.styl"
.button-text
  display inline-block
  min-width 200px
  padding 15px 30px 14px
  height 50px
  text-align center
  font-weight 600
  border 1px solid transparent
  transition 200ms
  cursor pointer
  border-radius 30px
  color $white
  &:active
    transform scale(0.99)
  &--disabled
    opacity .2
    pointer-events none
  &--loader
    pointer-events none

</style>