<template>
<footer class="footer" id="contact">
  <div class="wrap">
    <div class="footer__body">
      <LogoBase class="footer__logo" :is-primary="false"/>
      <item-icon-base class="footer__address">
        <template v-slot:icon><IconBase type="point" class="footer__address__icon"/></template>
        <template v-slot:content>г. Санкт-Петербург, ВН.ТЕР.Г.Муниципальный округ Полюстрово, пр-кт Пискарёвский, д. 63, лит. А, офис 148, пом.3-Н, 195273</template>
      </item-icon-base>
      <ContactList/>
      <to-order v-if="!isMobile" class="footer__order"><button-text-secondary class="footer__button">Заказать грузоперевозку</button-text-secondary></to-order>
    </div>
    <div class="footer__contact">
      <nav class="footer__docs">
        <to-policy class="footer__docs__item footer__docs__item--link"><link-base>Политика конфиденциальности</link-base></to-policy>
        <to-oferta class="footer__docs__item footer__docs__item--link"><link-base>Договор публичной оферты</link-base></to-oferta>
        <div class="footer__docs__item">{{ copyright }}</div>
      </nav>
    </div>
  </div>
</footer>
</template>

<script>
import LogoBase from "@/shared/ui/LogoBase";
import ToOrder from "@/features/ToOrder";
import ContactList from "@/widgets/ContactList";
import ToPolicy from "@/features/ToPolicy";
import {mapGetters} from "vuex";
import ToOferta from "@/features/ToOferta";
import ItemIconBase from "@/shared/ui/ItemIconBase";
import IconBase from "@/shared/ui/IconBase";
import ButtonTextSecondary from "@/shared/ui/ButtonTextSecondary";
import LinkBase from "@/shared/ui/LinkBase";
export default {
  name: "TheFooter",
  components: {
    LinkBase,
    ButtonTextSecondary,
    IconBase, ItemIconBase, ToOferta, ToPolicy, ContactList, ToOrder, LogoBase},
  computed:{
    ...mapGetters(['getWindowSize']),
    copyright(){
      // return '© ООО "ТИЗА Логистик", 2023 - ' + new Date().getFullYear()
      return '© ООО "ТЕРМИНАЛ СПБ", 2023'
    },
    isMobile(){
      return this.getWindowSize?.isMobile
    },
  },
}
</script>

<style lang="stylus">
@import "@/shared/styl/variables.styl"
.footer
  background-color $main
  color $white
  &__address
    max-width 500px
    align-items flex-start!important
    gap 10px!important
    &__icon
      color $accent
  &__body
    padding-top 25px
    padding-bottom 20px
    border-bottom 1px solid $dont-accent
    display flex
    flex-wrap wrap
    justify-content space-between
    align-items center
    gap 30px
    @media screen and (min-width 1300px)
      flex-wrap nowrap
  &__contact
    padding-top 20px
    padding-bottom 30px
    display flex
    justify-content space-between
    align-items flex-start
    flex-wrap wrap
    gap 40px
    &__list
      display flex
      flex-direction column
      gap 10px
      @media screen and (min-width 768px)
        flex-direction row
        gap 30px
  &__tel
    text-align right
    margin-left auto
    &__number
      font-size 20px
      font-style normal
      font-weight 600
      margin-bottom 5px
    &__caption
      font-style 13px
  &__docs
    display flex
    justify-content center
    align-items center
    gap 20px
    &__item
      font-size 12px
  &__order,
  &__button
    @media screen and (max-width 1300px)
      width 100%
      max-width 100%

</style>